import { render, staticRenderFns } from "./Ibirama.vue?vue&type=template&id=446a3109&scoped=true&"
import script from "./Ibirama.vue?vue&type=script&lang=js&"
export * from "./Ibirama.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "446a3109",
  null
  
)

export default component.exports